import React from "react"
import Seo from "../../components/seo"
import Trusted from "../../components/trusted"
import Faq from "../../components/faq"
import { useState, useLayoutEffect, useEffect } from "react"
import { CheckoutInfoContext } from "../../Contexts/ItemInfoCheckout"
import CheckoutContainer from "../../components/checkout/checkoutContainer"
import HeaderLp from "../../components/landing-pages/headerLp"
import StickyCtaClosable from "../../components/landing-pages/stickyCtaClosable"
import { useContext } from "react"
import { GlobalAuthContext } from "../../Contexts/GlobalAuthContext"
import saleDate from "../../utils/saleDate"

const CheckoutPage = props => {
  //using useLayoutEffect instead of useEffect because we want state to change before rendering -> prevents flickering/bad UX
  //with this we check if we are passing some information with gatsby Link in location state
  useLayoutEffect(() => {
    // console.log("props", props)
    if (props.location.state !== null) {
      if (props.location.state.product !== undefined) {
        setAllInfo(props.location.state)
        setItemSelected(true)
      }
    } else {
      setItemSelected(false)
    }
  }, [])

  const [email, setEmail] = useState("")
  const [fname, setFname] = useState("")
  const [lname, setLname] = useState("")
  const [path, setPath] = useState("")
  //state that manages checkout next step
  const [itemSelected, setItemSelected] = useState(false)

  //billing type and number of users
  const [billingType, setBillingType] = useState("")

  //state managing object with all checkout info
  const [allInfo, setAllInfo] = useState({})
  const [isChecked, setIsChecked] = useState(true)

  //state managing number of users per license
  //initial number of users
  const [initialNumber, setInitialNumber] = useState(1)

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user"))) {
      let order = JSON.parse(localStorage.getItem("user"))
      setEmail(order.true_email)
      setFname(order.first_name)
      setLname(order.last_name)
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    })
  }, [])

  const defaultPrice = {
    Checkout_1_visible: false,
    Checkout_1_best: true,
    Checkout_2_visible: false,
    Checkout_2_best: false,
    Checkout_3_visible: false,
    Checkout_3_best: false,
    Checkout_4_visible: true,
    Checkout_4_best: false,
    Checkout_4_name: "Monthly with OCR",
    Checkout_4_price: "10",
    Checkout_4_ocr: "2",
    Checkout_4_valute: "$",
    Checkout_4_ocr_checked: true,
    Checkout_5_visible: true,
    Checkout_5_best: false,
    Checkout_5_name: "Monthly",
    Checkout_5_ocr: "2",
    Checkout_5_price: "8",
    Checkout_5_valute: "$",
    Checkout_5_ocr_checked: false,
  }

  const { closedSticky, setClosedSticky } = useContext(GlobalAuthContext)

  return (
    <>
      <Seo title="Checkout" noindex={true} />
      {closedSticky == false && (
        <StickyCtaClosable
          date={`Get 38% off for a limited time! Sale ends ${saleDate()}.`}
        ></StickyCtaClosable>
      )}
      <HeaderLp
        wrapLocation={props.location}
        closedSticky={closedSticky}
        location={`aulp`}
      />

      <CheckoutInfoContext.Provider
        value={{
          itemSelected,
          setItemSelected,
          billingType,
          setBillingType,
          allInfo,
          setAllInfo,
          initialNumber,
          setInitialNumber,
          email,
          setEmail,
          fname,
          setFname,
          lname,
          setLname,
          path,
          setPath,
          isChecked,
          setIsChecked,
        }}
      >
        <CheckoutContainer wrapLocation={"/uklp"} priceInfo={defaultPrice} />
        {!itemSelected && (
          <>
            <Faq></Faq>
            <Trusted></Trusted>
          </>
        )}
      </CheckoutInfoContext.Provider>
    </>
  )
}

export default CheckoutPage
